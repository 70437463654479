import { view } from '@risingstack/react-easy-state'
import React, { useEffect, useState } from 'react'
import GenericPageContainer from '../../../GenericPageElements/GenericPageContainer'
import request from '../../../helpers/request'
import endpoints from '../../../helpers/endpoints'
import Select from 'react-select'

export default view(function VoaOccupier() {
    const [translations, setTranslations] = useState({})
    const [searchType, setSearchType] = useState({label: 'Postcode', value: 'postcode'})
    const [search, setSearch] = useState('')
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])

    useEffect(() => {
        request(true).get('/translate?key=voa_occupier')
        .then(e => {
          setTranslations(e.data)
        })
    }, [])

    useEffect(() => {
        setSearch('')
    }, [searchType])

    const handleSearch = () => {
        setLoading(true)
        request(true).post(endpoints.VOA_OCCUPIER_SEARCH, {
            [searchType.value]: search
        }).then(r => {
            setData(r.data)
            setLoading(false)
        }).catch(error => {
            setLoading(false)
            console.log(error)
        })
    }

    return (
        <GenericPageContainer
            title={translations['voa_occupier_title']}
            titleIconClass={"fak fa-id-business-icon fa-2x colour-primary"}
            containerWidth={'92vw'}
        >
            <div className="grid grid-gap-15 uprn-search">
                <div className="grid grid-columns-2 grid-gap-15" style={{ gridTemplateColumns: 'min-content min-content auto' }}>
                    <input
                        type="text"
                        value={search}
                        onChange={(e) => setSearch(searchType?.value === "postcode" ? e.target.value.toUpperCase() : e.target.value)}
                        placeholder={`Enter ${searchType.label}`}
                        className="data-row-field"
                        onKeyDown={(event) => {
                            if (event.key === 'Enter' ) {
                                handleSearch();
                            }
                        }}
                        disabled={!searchType}
                    />   
                    <Select
                        styles={{
                            control: (baseStyles) => ({
                            ...baseStyles,
                            minWidth: '15em',
                            }),
                        }}
                        options={[
                            {label: 'Postcode', value: 'postcode'},
                            {label: 'UPRN', value: 'uprn'},
                            {label: 'UARN', value: 'uarn'},
                            {label: 'VOA Reference', value: 'voaReference'},
                        ]}
                        defaultValue={{label: 'UPRN', value: 'uprn'}}
                        value={searchType}
                        onChange={(selected) => setSearchType(selected)}
                    />
                    <button disabled={loading} style={{ width: '10em' }} className="button compact smaller-text background-primary colour-white" onClick={handleSearch}>
                        {
                            loading ? 
                                <i className="fa fa-spinner fa-spin"></i>
                            :
                                "Search"
                        }
                    </button>
                </div>
                <div>
                    <table className='table borders squish smaller-text'>
                        <thead>
                            <tr>
                                <th>{translations['BA_Reference_Number']}</th>
                                <th>{translations['Primary_Description_Text']}</th>
                                <th>{translations['UARN']}</th>
                                <th>{translations['Full_Property_Identifier']}</th>
                                <th>{translations['Postcode']}</th>
                                <th>{translations['Current_From_Date']}</th>
                                <th>{translations['Current_To_Date']}</th>
                                <th>{translations['Effective_Status']}</th>
                                <th>{translations['ABP_UPRN']}</th>
                                <th>{translations['File_As_At_Date']}</th>
                                <th>{translations['Liable_Party']}</th>
                                <th>{translations['Liable_From']}</th>
                                <th>{translations['Empty_From']}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                loading ?
                                    <tr><td colSpan={'100%'}><i className="fa fa-spinner fa-spin"/></td></tr>
                                : data.length > 0 ?
                                    <>
                                        {data.map(datum => {
                                            return (
                                                <tr>
                                                    <td>{datum.reference_business_rate.BA_Reference_Number}</td>
                                                    <td>{datum.reference_business_rate.Primary_Description_Text}</td>
                                                    <td>{datum.reference_business_rate.Unique_Address_Reference_Number_UARN}</td>
                                                    <td>{datum.reference_business_rate.Full_Property_Identifier}</td>
                                                    <td>{datum.reference_business_rate.Postcode}</td>
                                                    <td style={{ whiteSpace: 'nowrap' }}>{datum.reference_business_rate.Current_From_Date}</td>
                                                    <td style={{ whiteSpace: 'nowrap' }}>{datum.reference_business_rate.Current_To_Date}</td>
                                                    <td>{datum.reference_business_rate.Effective_Status}</td>
                                                    <td>{datum.reference_business_rate.ABP_UPRN}</td>
                                                    <td style={{ whiteSpace: 'nowrap' }}>{datum.File_As_At_Date}</td>
                                                    <td>{datum.Liable_Party_1}</td>
                                                    <td style={{ whiteSpace: 'nowrap' }}>{datum.Liable_From}</td>
                                                    <td>{datum.Empty_From}</td>
                                                </tr>
                                            )
                                        })}
                                    </>
                                :
                                    <tr>{Array(13).fill(null).map((_, i) => <td key={i}></td>)}</tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </GenericPageContainer>
    )
})